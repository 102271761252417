import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, Inject, NgModule, PLATFORM_ID, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { environment } from '@env/environment';

import * as Sentry from '@sentry/angular';

import { ActivatedRoute, Router } from '@angular/router';

import {
  ConfigService,
  CoreModule,
  CredentialsService,
  DebugService,
  I18nService,
  setPlatformId,
  setRoute,
  setRouter,
} from '@core';
import { SharedModule } from '@shared/shared.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ApiModule, Configuration, Locale, TranslationsService } from '@api';

import { AuthModule } from '@app/auth/auth.module';
import { ListModule as ProjectsListModule } from './projects/list/list.module';
import { DemoModule as CcfDemoModule } from '@app/ccf/demo/demo.module';

import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { map } from 'rxjs/operators';

let translationsHash: string; // adding ConfigService to the TranslateModule loader deps issues cyclic dependency

export class ApiTranslateLoader implements TranslateLoader {
  constructor(public translationsService: TranslationsService) {}

  getTranslation(locale: string) {
    return this.translationsService.uiTranslationsList(locale, translationsHash).pipe(map((res) => res.data));
  }
}

const providers: Provider[] = [];

if (environment.sentryUrl) {
  providers.push({
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      // https://docs.sentry.io/platforms/javascript/enriching-events/user-feedback/
      // showDialog: true,
    }),
  });
  providers.push({
    provide: Sentry.TraceService,
    deps: [Router],
  });
  providers.push({
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  });
}

providers.push({
  provide: Configuration,
  useFactory: (credentialsService: CredentialsService) =>
    new Configuration({
      basePath: environment.serverUrl,
      credentials: {
        bearer: () => {
          const credentials = credentialsService.credentials$.getValue();
          return credentials ? credentials.token : null;
        },
      },
    }),
  deps: [CredentialsService],
  multi: false,
});

providers.push({
  provide: APP_INITIALIZER,
  useFactory: (debugService: DebugService, configService: ConfigService, i18nService: I18nService) => {
    return () => {
      return new Promise<boolean>((resolve, reject) => {
        Promise.all([
          debugService.loadDebugbar(),
          new Promise<boolean>((resolve, reject) => {
            configService.translationsConfig$.subscribe(
              (config) => {
                if (config) {
                  translationsHash = config.hash; // adding ConfigService to the TranslateModule loader deps issues cyclic dependency

                  const locales: Locale[] = JSON.parse(JSON.stringify(config.locales || [])); // clone to prevent changes in the original object

                  let defaultLocale = null;
                  if (locales && locales.length) {
                    defaultLocale = locales.find((locale) => locale.is_current);
                    if (!defaultLocale) {
                      defaultLocale = locales.find((locale) => locale.is_default);
                    }
                    if (!defaultLocale) {
                      defaultLocale = locales[0];
                    }
                  }

                  i18nService.init(
                    defaultLocale ? defaultLocale.code : null,
                    locales ? locales.map((locale) => locale.code) : [],
                  );

                  i18nService.currentLocale$.subscribe(
                    () => resolve(true),
                    (err) => reject(err),
                  );
                }
              },
              (err) => reject(err),
            );
          }),
        ]).then(
          (results) => {
            resolve(!results.find((result) => !result));
          },
          (err) => reject(err),
        );
      });
    };
  },
  deps: [DebugService, ConfigService, I18nService],
  multi: true,
});

providers.push(ConfirmationService);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: ApiTranslateLoader,
        deps: [TranslationsService],
      },
    }),
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    CoreModule,
    SharedModule,
    ShellModule,
    ApiModule,
    AuthModule,
    ProjectsListModule,
    CcfDemoModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    @Inject(PLATFORM_ID) public platformId?: string,
  ) {
    setRoute(route);
    setRouter(router);
    setPlatformId(platformId);
  }
}
