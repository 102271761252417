import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { News, NewsService as ApiNewsService } from '@api';
import { TitleService } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { NewsService } from './news.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { Table } from 'primeng/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-shell-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() reloadTimestamp: number;

  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();

  isLoading: number;
  isSaving: number;

  reloadInterval: number;

  showNewsDialog: boolean;
  currentNews: News;

  read: boolean;

  @ViewChild('recordsTable') recordsTable: Table;

  constructor(
    public recordsService: NewsService,
    public apiNewsService: ApiNewsService,
    public translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public confirmationService: ConfirmationService,
    public titleService: TitleService,
  ) {
    this.recordsService.fields = JSON.stringify(['id', 'title', 'created_at', 'reads']);
    this.reset();
  }

  reset() {
    this.recordsService.disabled = this.disabled;

    this.isLoading = 0;
    this.isSaving = 0;

    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
      this.reloadInterval = null;
    }

    this.currentNews = null;
    this.showNewsDialog = !!this.currentNews;

    this.read = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reset();
    if (this.recordsTable) {
      this.recordsTable.sortField = null;
      this.recordsTable.sortOrder = null;
      this.recordsTable.reset();
    }
    this.ngOnInit().then();
  }

  async ngOnInit() {
    if (this.reloadTimestamp) {
      this.titleService.push(await this.translateService.get('ui.app.shell.news.news-component-ts.news').toPromise());

      this.recordsService.reload(); // recordsService pre-loads on startup so we reload only on changes

      this.reloadInterval = setInterval(() => this.recordsService.reload(), 60 * 1000); // once every minute
    }
  }

  openNews(news: News) {
    if (!news) {
      return;
    }

    this.isLoading++;

    this.apiNewsService
      .newsDetails(news.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.isLoading--;

          this.currentNews = res.data;
          this.showNewsDialog = !!this.currentNews;
        },
        () => this.isLoading--,
      );
  }

  onHideNewsDialog() {
    if (this.currentNews) {
      // Just mark the rendered table record as read to avoid list reloads
      this.recordsService.records$.pipe(untilDestroyed(this), first()).subscribe((records) =>
        records.forEach((record) => {
          if (record.id === this.currentNews?.id) {
            record.reads = 1;
          }
        }),
      );

      this.read = true;
      this.apiNewsService
        .newsRead(this.currentNews.id, null, null, null, true)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          // this.recordsService.reload(); // already marked as read
        });

      this.currentNews = null;
    }
  }

  async markAllAsRead() {
    this.confirmationService.confirm({
      header: await this.translateService.get('ui.app.shell.news.news-component-ts.mark-all-as-read').toPromise(),
      message: await this.translateService
        .get('ui.app.shell.news.news-component-ts.are-you-sure-you-want-to-mark-all-news-as-read')
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.shell.news.news-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService.get('ui.app.shell.news.news-component-ts.cancel').toPromise(),
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.read = true;

        const handler = () => {
          this.isSaving--;
          this.isLoading--;
        };

        this.isSaving++;
        this.isLoading++;
        this.apiNewsService
          .newsReadAll()
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            handler();

            this.cancel();

            this.recordsService.reload();
          }, handler);
      },
    });
  }

  cancel() {
    if (this.read) {
      this.authenticationService.refreshProfile().then();
    }

    this.cancelled.emit();
    this.titleService.pop();

    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
      this.reloadInterval = null;
    }

    this.reloadTimestamp = null;
  }
}
